<template>
  <div class="daily-report">
    <div class="page-header">
      <h1>{{ $t('Report') }} > {{$t('Daily Report') }} </h1>
    </div>
    <div class="page-content">
      <div class="content-tabs">
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane :label="$t('Total Sales')" name="Total Sales">
            <TodaySales />
          </el-tab-pane>
          <el-tab-pane :label="$t('Total Orders')" name="Total Orders">
            <TodayOrders />
          </el-tab-pane>
          <el-tab-pane :label="$t('Total Shoppers')" name="Total Shoppers">
            <TodayShoppers />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TodaySales from '@/components/report/TodaySales.vue';
import TodayOrders from '@/components/report/TodayOrders.vue';
import TodayShoppers from '@/components/report/TodayShoppers.vue'

export default {
  name: 'DailyReport',
  components: {
    TodaySales,
    TodayOrders,
    TodayShoppers,
  },
  data(){
    return {
      activeName: 'Total Sales',
    };
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.daily-report{
  .page-content .content-tabs .el-tabs .el-tabs__content{
    display: block;
    padding-left: 25px;
  }
}
</style>
