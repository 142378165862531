<template>
  <div class="order-list-wrapper">
    <div class="order-list-message">
      <div class="total-sales">
        {{ $t('The total order number of today is:') }} {{ orders.length }}
      </div>
    </div>
    <div class="order-list">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ $t('Order No.') }}</th>
            <th scope="col">{{ $t('Date') }}</th>
            <th scope="col">{{ $t('Total') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders">
            <td>{{ order.reference_id }}</td>
            <td>{{ formatDateTime(order.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
            <td>HK$ {{ loadOrderAmount(order) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Order from '@/lib/order';
import Customer from '@/lib/customer';
import Report from '@/lib/report';

export default {
  name: 'TodayOrders',
  mounted(){
    this.loadTodayOrders();
  },
  data(){
    return {
      orders: [],
    }
  },
  methods:{
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    loadOrderAmount(order){
      const relatedServices = JSON.parse(order.related_service);
      const serviceInfos = order.service_infos;
      const totalAmount = this.loadItemAmount(serviceInfos, relatedServices);
      return totalAmount;
    },
    loadItemAmount(serviceInfos, relatedServices){
      let totalAmount = 0;
      for(let i = 0; i < relatedServices.length; i++ ){
        const currentService = relatedServices[i];
        const serviceInfo = serviceInfos.find(function(item){
          return currentService === item.id;
        });
        const servicePrice = Number(serviceInfo['service_price']);
        totalAmount += servicePrice;
      }
      return totalAmount;
    },
    async loadTodayOrders(){
      try{
        const loginInfo = Common.getLoginInfo();
        const orders = await Report.loadTodayOrders(this.apiUrl, loginInfo);
        this.orders = orders;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.total-sales{
  padding-left: 19px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
